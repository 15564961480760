import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Typography, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import Fuse from 'fuse.js';
import wineryData from '../data/wineryData';

const fuse = new Fuse(wineryData, {
    keys: ['name', 'address', 'description', 'website'],
    threshold: 0.3,
    shouldSort: true,
});

const ChatBot = () => {
    const [chat, setChat] = useState([]);
    const [input, setInput] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [hasSentMessage, setHasSentMessage] = useState(false);
    const chatWindowRef = useRef(null);
    const chatMessagesRef = useRef(null);
    const chatEndRef = useRef(null);
    const isMobile = window.innerWidth <= 600;
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    // Update window height (for mobile keyboard responsiveness)
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSend = async () => {
        if (input.trim() === '') return;

        setHasSentMessage(true);

        const userMessage = input;
        const newChat = [...chat, { role: 'user', content: userMessage }];
        setChat(newChat); // Update state with user message first
        setInput('');

        let response = '';

        // **Improved Winery Lookup**
        const wineryContext = (() => {
            const lowerCaseMessage = userMessage.toLowerCase();
            return wineryData.find(winery => lowerCaseMessage.includes(winery.name.toLowerCase())) || null;
        })();

        try {
            const openaiResponse = await fetch('/api/chat', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    messages: [
                        {
                            role: 'system',
                            content:
`You are a wine expert specializing in Lake Michigan Shore and Fennville wines.
You can **only** recommend wineries from the following list:
${wineryData.map(winery => `${winery.name}`).join(', ')}.

${
    wineryContext
        ? `The user is asking about the winery "${wineryContext.name}". You may mention it by name, but only provide additional details if explicitly asked.`
        : `If recommending wineries, only suggest names from the approved list. Do **not** make up wineries that are not in the provided list.`
}

If the user asks about wine styles, grape varieties, or winemaking, provide correct information even if no winery is referenced.
`
                        },
                        ...chat, // **Send full conversation history for context**
                        { role: 'user', content: userMessage },
                        ...(wineryContext
                            ? [
                                  {
                                      role: 'system',
                                      content:
`Winery Details:
Name: ${wineryContext.name}
Address: ${wineryContext.address}
Description: ${wineryContext.description}
AVA: ${wineryContext.ava}
Website: ${wineryContext.website}`
                                  }
                              ]
                            : [])
                    ]
                })
            });

            const data = await openaiResponse.json();
            response = data.choices[0].message.content.trim();
        } catch (error) {
            console.error("Error creating completion:", error);
            response = "Sorry, this app is created and maintained for free, and fincuvaAI has a cost. Unfortunately, the app is too popular at the moment, and responses have been limited. Please try again later, or if you really are enjoying the app, consider donating [here](https://buymeacoffee.com/mikerodd).";
        }        

        // Append bot response at the bottom
        setChat(prevChat => [...prevChat, { role: 'system', content: response }]);
    };

    // Scroll chat messages container to bottom when new message arrives
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chat, isOpen]); // Scroll when chat updates or opens
    
    

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isOpen]);
    
    

    useEffect(() => {
        function handleClickOutside(event) {
            if (!chatWindowRef.current) return;
    
            // Get bounding box of the chat window
            const chatBox = chatWindowRef.current.getBoundingClientRect();
    
            // Get scrollbar width (varies by browser)
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    
            // If user clicked inside the chat window, do nothing
            if (
                event.clientX >= chatBox.left &&
                event.clientX <= chatBox.right &&
                event.clientY >= chatBox.top &&
                event.clientY <= chatBox.bottom
            ) {
                return;
            }
    
            // If user clicked on the scrollbar, do nothing
            if (event.clientX > window.innerWidth - scrollbarWidth) {
                return;
            }
    
            // Otherwise, close the chat
            setIsOpen(false);
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [chatWindowRef]);
    
    
    

    return (
        <Box sx={{ position: 'fixed', bottom: isMobile ? 20 : 35, right: isMobile ? 20 : 55, zIndex: 4002 }}>
            {/* Floating Chat Icon */}
            <IconButton
                color="primary"
                onClick={() => setIsOpen(!isOpen)}
                sx={{
                    backgroundColor: '#7b1fa2',
                    '&:hover': { backgroundColor: '#682a75' },
                    borderRadius: '50%',
                    padding: isMobile ? 2 : 4,
                    width: isMobile ? 50 : 70,
                    height: isMobile ? 50 : 70
                }}
                aria-label="Toggle chat"
            >
                <ChatIcon sx={{ color: '#ffffff', fontSize: isMobile ? 28 : 40 }} />
            </IconButton>

            {/* Background overlay for desktop */}
            {isOpen && !isMobile && (
                <Box
                    onClick={() => setIsOpen(false)}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        zIndex: 3001,
                    }}
                />
            )}

            {/* Chat Window */}
            {isOpen && (
                <Box
                ref={chatWindowRef}
                sx={{
                    position: 'fixed',
                    top: isMobile ? 0 : '50%',
                    left: isMobile ? 0 : '50%',
                    width: isMobile ? '100vw' : '30vw',
                    height: isMobile ? `${windowHeight}px` : '60vh',
                    transform: isMobile ? 'none' : 'translate(-50%, -50%)',
                    backgroundColor: '#fff',
                    boxShadow: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: 3002,
                    borderRadius: isMobile ? 0 : '16px',
                    overflowY: 'auto',
                }}
            >
            
                    {/* Header with Close Button on Mobile */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 0,
                            boxShadow: 0.5,
                            borderRadius: isMobile ? '0px' : '8px 8px 0 0',
                            minHeight: '80px',
                            position: 'sticky',
                            top: 0,
                            zIndex: 2,
                            backgroundColor: '#fff'
                        }}
                    >
                        <img
                            src="/assets/fincuvaAI.png"
                            alt="fincuva AI"
                            style={{ width: isMobile ? '150px' : '220px', padding: isMobile ? '10px' : '0px' }}
                        />
                        {isMobile && (
                            <IconButton
                                onClick={() => setIsOpen(false)}
                                sx={{
                                    color: 'black',
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    backgroundColor: 'rgba(255,255,255,0.8)'
                                }}
                            >
                                <CloseIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        )}
                    </Box>

                    {/* Chat Messages */}
                    <Box
                        ref={chatMessagesRef}
                        sx={{
                            flexGrow: 1,
                            //overflowY: 'auto',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {chat.map((msg, index) => (
                            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Box
                                    sx={{
                                        backgroundColor: msg.role === 'user' ? '#f7f2f7' : '#f3e5f5',
                                        borderRadius: '30px',
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        maxWidth: '80%',
                                        alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start'
                                    }}
                                >
                                    <Typography variant="bodychat">
                                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                        <div ref={chatEndRef} />
                    </Box>

                    {/* Input Field */}
                    <TextField
                        variant="outlined"
                        fullWidth
                        placeholder={hasSentMessage ? '' : 'Ask about Michigan wines...'}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSend();
                            }
                        }}
                        autoComplete="off"
                        inputProps={{
                            style: { fontSize: '16px' },
                            autoCorrect: 'off',
                            autoCapitalize: 'none',
                            spellCheck: 'false'
                        }}
                        sx={{
                            fontFamily: 'Roboto',
                            p: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '16px',
                                '& fieldset': { borderColor: '#ddc5e3' },
                                '&:hover fieldset': { borderColor: '#ddc5e3' },
                                '&.Mui-focused fieldset': { borderColor: '#ddc5e3' }
                            }
                        }}
                        aria-label="User input"
                    />
                </Box>
            )}
        </Box>
    );
};

export default ChatBot;
